<template lang="pug">
div
  button(class='text-sm hover:underline' @click='openCartManager')
    template(v-if='activeCart') Active Cart: #[span(class='font-bold') {{ activeCart.name }}]
    template(v-else) No Active Customer Cart

  Overlay(unique-key='cartManager' modal-width='WIDE' @close='closeCartManager')
    template(#header)
      div(class='w-full flex justify-center items-center')
        p(class='title')
          template(v-if='showCartForm') Create Customer Cart
          template(v-else) Cart Manager

    div(class='relative')
      template(v-if='isLoading')
        div(class='absolute inset-0 z-20 bg-white opacity-80')
        div(class='absolute inset-0 z-20 flex justify-center items-center')
          SpinnerText(text='Loading' is-dark)

      template(v-if='showCartForm')
        div(class='p-5')
          CartManagerForm(:cart='cartToEdit' @cancel='resetCartForm' @submit='saveCart')

      template(v-else)
        div(class='space-y-3')
          div(v-if='carts.length === 0' class='text-center pt-10 pb-6 px-5')
            p(class='font-bold Ztext-lg') No Customer Carts
            p(class='mt-1 text-sm') Get started by creating a new cart

          ul(v-else class='divide-y divide-gray-light p-3')
            template(v-for='(cart, index) in carts' :key='index')
              li(class='px-2 py-3.5 hover:bg-gray-lightest')
                CartManagerInstance(
                  :key='cart.id'
                  :cart='cart'
                  @activate='activateCart'
                  @deactivate='activateOriginalCart'
                  @edit='editCart'
                  @remove='deleteCart'
                )

          div(v-if='carts.length < 8' class='pb-6 text-center')
            BaseButton(
              color='ACTION'
              size='SMALL'
              type='button'
              has-custom-padding
              class='px-3 py-2.5'
              @click='showCartForm = true'
            )
              | + Create New Cart

  CartManagerPaylinkForm
</template>

<script setup lang="ts">
const { $overlay, $cognito, $sitewideConfig } = useNuxtApp()
const rootStore = useRootStore()
const cartStore = useCartStore()
const fitmentStore = useFitmentStore()
const fitmentDisplayStore = useFitmentDisplayStore()
const route = useRoute()
const { isIntegrationEnabled } = useUtils()
const { getApiUrl } = useUrls()
const routeBuilder = useRouteBuilder()

const activePermIdCookie = useCookie('activePermId')

declare global {
  interface CartInstance {
    id: string
    name: string
    fitmentData: Fitment
    currentRoute: any
    leadSource: 'CHAT' | 'PHONE'
  }
}
const carts = ref<CartInstance[]>(JSON.parse(localStorage.getItem('carts')) || [])

const cartToEdit = ref<CartInstance | null>()
const showCartForm = ref(false)
const isLoading = ref(false)

const permId = computed(() => {
  return rootStore.permId
})

const activeCart = computed(() => {
  return carts.value?.find((cart) => cart.id === permId.value)
})

watch(carts.value, (newVal) => {
  localStorage.setItem('carts', JSON.stringify(newVal))
})

watch(activeCart, (newVal) => {
  // If activeCart doesn't exist then we've deleted the cart so we need to restore our original cart
  if (!newVal) activateOriginalCart()
})

watch(
  () => route.path,
  () => {
    if (!activeCart.value) return
    activeCart.value.currentRoute = route.fullPath
  }
)

watch(fitmentStore.$state, (newVal) => {
  if (!activeCart.value) return

  if (Object.keys(newVal).length === 0) {
    activeCart.value.fitmentData = {}
  } else {
    activeCart.value.fitmentData = { ...newVal }
  }
})

onMounted(() => {
  // This is to keep local storage state in sync between both tabs
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event
  window.addEventListener('storage', storageSync)

  // This is to make sure that the underlying active perm id cookie is set correctly
  // when the tab or window is reactivated
  document.addEventListener('visibilitychange', visibilityChange)
})

onUnmounted(() => {
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event
  window.removeEventListener('storage', storageSync)

  document.removeEventListener('visibilitychange', visibilityChange)
})

function storageSync(event) {
  if (event.key !== 'carts') return
  carts.value = JSON.parse(localStorage.getItem('carts'))
}

function visibilityChange() {
  if (document.visibilityState === 'visible') {
    if (activeCart.value) activateCart(activeCart.value.id)
    else activateOriginalCart()
  }
}

function openCartManager() {
  showCartForm.value = false
  $overlay.open('cartManager')
}

function closeCartManager() {
  if (isLoading.value) return
  $overlay.close()
}

async function saveCart(cartData: CartInstance) {
  let cartId = cartData.id

  // If we have an id then we are editing a cart
  if (cartId) {
    const index = carts.value.findIndex(({ id }) => id === cartId)
    if (index > -1) carts.value.splice(index, 1, cartData)
  } else {
    cartId = createPermId()

    carts.value.push({
      ...cartData,
      id: cartId,
      currentRoute: {
        // Only creating a route object so we can delete queries from new carts
        name: route.name,
        params: Object.assign({}, route.params),
        query: Object.assign({}, route.query),
      },
    })

    await activateCart(cartId)
  }

  isLoading.value = true

  await setLeadSource(cartId, cartData.leadSource)

  isLoading.value = false

  resetCartForm()
}

function editCart(cart: CartInstance) {
  cartToEdit.value = cart
  showCartForm.value = true
}

async function deleteCart(id: string) {
  const index = carts.value.findIndex((cart) => cart.id === id)
  if (index > -1) carts.value.splice(index, 1)
  await cartStore.clearCart(id)
}

function resetCartForm() {
  showCartForm.value = false
  cartToEdit.value = null
}

async function activateCart(id: string) {
  if (isLoading.value) return

  isLoading.value = true

  await setActiveCart(id)

  if (activeCart.value?.fitmentData) {
    fitmentDisplayStore.clearFitment()
    fitmentDisplayStore.saveFitment(activeCart.value.fitmentData)
  } else {
    fitmentDisplayStore.clearFitment()

    // Refresh url to reset product page drills on new carts
    if (activeCart.value?.currentRoute) {
      // We need to build a new route object from the currentRoute because we are saving it as a string
      const newRoute = routeBuilder.formatRoute(activeCart.value.currentRoute)
      newRoute.query.z = Date.now().toString()
      activeCart.value.currentRoute = newRoute
    }

    // Delete sameDayShipping for new carts on category pages
    if (activeCart.value?.currentRoute?.query?.sameDayShipping)
      delete activeCart.value.currentRoute.query.sameDayShipping
  }

  // We need to delete mod from the url so mobile menu does not break
  if (activeCart.value?.currentRoute?.query?.mod) delete activeCart.value.currentRoute.query.mod

  navigateTo(routeBuilder.formatRoute(activeCart.value?.currentRoute) || { name: 'home ' })

  // Set the currentRoute to the fullPath so its a string value and not a route obj
  // TODOLATER: Might just want to save the currentRoute as a route obj and use fullPath for display instead of doing this
  if (activeCart.value?.currentRoute) activeCart.value.currentRoute = route.fullPath

  isLoading.value = false
}

async function activateOriginalCart() {
  if (isLoading.value) return

  isLoading.value = true

  await cartStore.restoreOriginalCart()

  fitmentDisplayStore.clearFitment()

  navigateTo({ name: 'home' })

  isLoading.value = false
}

const isLoggedIn = computed(() => {
  return $cognito.isLoggedIn()
})

async function setActiveCart(permId: string) {
  if (isIntegrationEnabled('cognito') && isLoggedIn.value && $cognito.isSalesAgent()) activePermIdCookie.value = permId

  // If the permId is already the permId that we want to set then we have no reason to do this part
  if (rootStore.permId === permId) return

  rootStore.setPermId(permId)

  await cartStore.getCart()
}

async function setLeadSource(cartId: string, leadSource: string) {
  const isCartActive = rootStore.permId === cartId

  // No reason to call this if the active cart lead source already is the same
  if (isCartActive && cartStore.cart.leadSource === leadSource) return

  const headers: { 'x-site': string; Authorization?: string } = {
    'x-site': $sitewideConfig.sitePrefix,
  }

  if (isIntegrationEnabled('cognito')) {
    const token = await $cognito.getToken()
    if (token) headers.Authorization = `Bearer ${token}`
  }

  const url = getApiUrl('cart')
  await $fetch(`${url}${cartId}`, {
    method: 'PUT',
    headers,
    body: {
      leadSource,
    },
  })

  if (isCartActive) await cartStore.getCart()
}
</script>
