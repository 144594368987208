<template lang="pug">
div(id='top')
  ClientOnly
    AgentToolbar(v-if='isIntegrationEnabled("cognito") && $cognito.isLoggedIn() && $cognito.isSalesAgent()')
  Header(id='Header')
  slot
  Footer(id='Footer')
  LazyPhotoswipeLightbox(
    v-if='lightboxShow'
    :index='lightboxIndex'
    :items='lightboxItems'
    @closed='lightboxShow = false'
  )

  //- Popup MiniCart
  Overlay(unique-key='mc')
    template(#noPadding)
      LazyCartMini(show-continue-shopping)
  Teleport(to='#teleports')
    Notifications
</template>
<script setup lang="ts">
const { $cognito } = useNuxtApp()
const { lightboxIndex, lightboxItems, lightboxShow } = usePhotoswipeLightbox().appVars
const { isIntegrationEnabled } = useUtils()
</script>
