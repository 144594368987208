<template lang="pug">
Overlay(unique-key='paylink' modal-width='WIDE' @closed='close')
  template(#header)
    div(class='w-full flex justify-center items-center')
      p(class='title') Send Paylink

  div
    FormKit(type='form' :actions='false' @submit='sendPaylink')
      template(#default='{ state }')
        BaseFormInputEmail(
          v-model='email'
          label='Customer Email'
          name='email'
          validation='required'
          :validation-messages='{ required: "Please enter a valid email" }'
        )
        div(class='flex justify-end gap-x-2')
          BaseButton(color='DANGER' size='SMALL' type='button' :is-waiting='state.loading' @click='cancel') Cancel
          BaseButton(color='SUCCESS' size='SMALL' type='submit' :is-waiting='state.loading') Send
</template>

<script setup lang="ts">
const { $overlay, $cognito, $sitewideConfig } = useNuxtApp()
const rootStore = useRootStore()
const notifications = useNotifications()
const { isIntegrationEnabled } = useUtils()
const { getApiUrl } = useUrls()
const url = getApiUrl('cart')

const email = ref('')
const isSending = ref(false)

async function sendPaylink() {
  if (isSending.value) return
  isSending.value = true

  try {
    const headers: { 'x-site': string; Authorization?: string } = {
      'x-site': $sitewideConfig.sitePrefix,
    }

    if (isIntegrationEnabled('cognito')) {
      const token = await $cognito.getToken()
      if (token) headers.Authorization = `Bearer ${token}`
    }

    await $fetch(`${url}${rootStore.permId}/paylink`, {
      method: 'POST',
      headers,
      body: {
        email: email.value,
      },
    })

    notifications.addSuccess('Paylink Success!', `Paylink has been sent to ${email.value}`)
    email.value = ''
    close()
  } catch (error) {
    email.value = ''
    notifications.addError('Paylink Error', error?.data?.msg || 'Unable to send paylink to customer')
  } finally {
    isSending.value = false
  }
}

function cancel() {
  email.value = ''
  $overlay.open('cartManager')
}

function close() {
  email.value = ''
  $overlay.close()
}
</script>
