<template lang="pug">
div(class='flex items-center justify-between gap-x-6')
  div(class='min-w-0')
    div(class='flex items-start gap-x-3')
      p(class='font-extrabold leading-6 text-gray-darkest') {{ cart.name }}
      p(
        v-if='isActive'
        class='rounded-md whitespace-nowrap font-bold text-xs text-white bg-success-light ring-1 ring-inset ring-success-dark px-1.5 py-0.5'
      ) Active

    div(class='mt-1 text-xs leading-5 text-gray-dark')
      //- We fallback to web here because existing carts in cart manager won't have a value but the default is web
      p(class='font-bold truncate') {{ cart.leadSource || 'WEB' }}
      p(class='font-bold truncate') {{ cart.currentRoute }}
      p(v-if='userFitment' class='font-bold truncate') {{ userFitment }}

  div(class='flex flex-none items-center gap-x-4')
    BaseButton(color='GRAY' button-style='OUTLINE' size='XSMALL' type='button' @click='toggleActivate')
      template(v-if='isActive') Deactivate
      template(v-else) Activate

    div(ref='optionsPanel' class='relative flex-none')
      button(
        type='button'
        class='block text-gray -m-2.5 p-2.5 hover:text-gray-darkest'
        :class='{ "text-gray-darkest": isOptionsOpen }'
        aria-expanded='false'
        aria-haspopup='true'
        @click='toggleOptionsPanel'
      )
        img(src='/images/ellipsisVertical.svg' height='20' width='20' alt='Options menu')

      transition(
        enter-active-class='transition ease-out duration-100'
        enter-from-class='transform opacity-0 scale-95'
        enter-to-class='opacity-100 scale-100'
        leave-active-class='transition ease-in duration-75'
        leave-from-class='opacity-100 scale-100'
        leave-to-class='transform opacity-0 scale-95'
      )
        div(
          v-if='isOptionsOpen'
          class='absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray-lighter focus:outline-none'
          role='menu'
          tabindex='-1'
        )
          div(class='py-1')
            button(
              v-if='isActive'
              class='block w-full text-left px-4 py-2 text-sm text-gray-darker disabled:opacity-30'
              :class='{ "hover:bg-gray-lightest hover:text-gray-darkest hover:font-bold": cartHasItems }'
              type='button'
              :disabled='!cartHasItems'
              @click='sendPaylink'
            ) Send Paylink
            button(
              class='block w-full text-left px-4 py-2 text-sm text-gray-darker hover:bg-gray-lightest hover:text-gray-darkest hover:font-bold'
              type='button'
              @click='edit'
            ) Edit
            button(
              class='block w-full text-left px-4 py-2 text-sm text-gray-darker hover:bg-gray-lightest hover:text-gray-darkest hover:font-bold'
              type='button'
              @click='remove'
            ) Remove
</template>

<script setup lang="ts">
const { $overlay } = useNuxtApp()
const rootStore = useRootStore()
const cartStore = useCartStore()

const emit = defineEmits(['activate', 'deactivate', 'edit', 'remove'])

const { cart } = defineProps<{
  cart: CartInstance
}>()

const isOptionsOpen = ref(false)
const optionsPanel = ref(null)

onClickOutside(optionsPanel, closeOptionsPanel)

const cartHasItems = computed(() => {
  return !!(cartStore.cart.items.length > 0)
})

const isActive = computed(() => {
  return cart.id === rootStore.permId
})

const userFitment = computed(() => {
  const fitment = []

  const { year, make, model, bed, body, engine } = cart.fitmentData || {}

  let primaryFitment = null

  if (year) primaryFitment = year
  if (make) primaryFitment += ` ${make}`
  if (model) primaryFitment += ` ${model}`

  if (primaryFitment) fitment.push(primaryFitment)

  if (bed) fitment.push(bed)
  if (body) fitment.push(body)
  if (engine) fitment.push(engine)

  return fitment.join(', ')
})

function toggleOptionsPanel() {
  isOptionsOpen.value = !isOptionsOpen.value
}

function closeOptionsPanel() {
  isOptionsOpen.value = false
}

function toggleActivate() {
  emit(isActive.value ? 'deactivate' : 'activate', cart.id)
}

function edit() {
  emit('edit', cart)
}

function remove() {
  emit('remove', cart.id)
}

function sendPaylink() {
  $overlay.open('paylink')
}
</script>
