<template lang="pug">
BaseForm(@submit='submit')
  BaseFormInput(
    v-model='cartData.name'
    label='Customer Name'
    :maxlength='32'
    name='name'
    type='text'
    validation='required'
    validation-label='Name'
  )

  BaseFormSelect(
    v-model='cartData.leadSource'
    label='Lead Source'
    name='leadSource'
    :options='[ { label: "Chat", value: "CHAT" }, { label: "Phone", value: "PHONE" }, ]'
    placeholder='Select lead source'
    validation='required'
  )

  div(class='flex justify-end gap-x-2')
    BaseButton(color='DANGER' size='SMALL' type='button' @click='cancel') Cancel
    BaseButton(color='SUCCESS' size='SMALL' type='submit') Save Cart
</template>

<script setup lang="ts">
const emit = defineEmits(['submit', 'cancel'])

const { cart } = defineProps<{
  cart: CartInstance
}>()

const cartData = ref<CartInstance>({
  ...cart,
  leadSource: cart?.leadSource,
})

function submit() {
  emit('submit', cartData.value)
}

function cancel() {
  emit('cancel')
}
</script>
